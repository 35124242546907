import { Backdrop, Box, Button, Checkbox, Fade, FormControlLabel, makeStyles, Modal } from "@material-ui/core";
import React from "react";
import { formatDateToDatetimeLocal } from "../../../utils/date";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      inputWrapper: {
        display: 'flex',
        flexDirection: 'column'
      },
      inputUrl: {
        width: '100%',
        display: 'flex',
        '& > input': {
            flex: '1'
        }
      }
}));

const PlannedSessionDetails = (props) => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open}>
                <div className={classes.paper}>
                    <h2 style={{ textAlign: 'center' }}>{ props.item ? props.item.title : '' }</h2>
                    <h4 style={{ textAlign: 'center' }}>{ props.item ? props.item.description : '' }</h4>

                    <Box p={1}>
                        <FormControlLabel style={{ paddingLeft: '12px' }} control={<input style={{ marginRight: '8px' }} type="datetime-local" value={props.item ? formatDateToDatetimeLocal(props.item.start) : undefined} disabled={true} />} label="Planned at" />
                        <FormControlLabel style={{ paddingLeft: '12px' }} control={<input style={{ marginRight: '8px' }} type="datetime-local" value={props.item ? formatDateToDatetimeLocal(props.item.end) : undefined} disabled={true} />} label="Expire at" />
                    </Box>

                    <Box p={1}>
                        <FormControlLabel control={<Checkbox checked={props.item != null && props.item.translation_allowed} disabled={true} />} label="Allow translation" />
                    </Box>

                    <Box p={1}>
                        <FormControlLabel className={classes.inputUrl} control={<input style={{ marginRight: '8px' }} type="text" value={props.item != null ? getWordsListTitle(props.item.wordsList, props.wordsList) : undefined} disabled={true} placeholder="aucune" />} label="Words List" />
                        <FormControlLabel className={classes.inputUrl} control={<input style={{ marginRight: '8px' }} type="text" value={props.item != null ? `#${props.item.conversationId}` : undefined} disabled={true} placeholder="aucun" />} label="Conversation ID" />
                    </Box>

                    <Box p={1} className={classes.inputWrapper}>
                        <FormControlLabel className={classes.inputUrl} control={<input style={{ marginRight: '8px' }} type="text" value={props.item != null ? getWebUrl(props.item.reviser_code) : undefined} disabled={true} placeholder="aucun" />} label="Reviser URL" />
                        <FormControlLabel className={classes.inputUrl} control={<input style={{ marginRight: '8px' }} type="text" value={props.item != null ? getWebUrl(props.item.chat_code) : undefined} disabled={true} placeholder="aucun" />} label="Chat URL" />
                        <FormControlLabel className={classes.inputUrl} control={<input style={{ marginRight: '8px' }} type="text" value={props.item != null ? getWebUrl(props.item.companion_code) : undefined} disabled={true} placeholder="aucun" />} label="Companion URL" />
                        <FormControlLabel className={classes.inputUrl} control={<input style={{ marginRight: '8px' }} type="text" value={props.item != null ? getWebUrl(props.item.guest_code) : undefined} disabled={true} placeholder="aucun" />} label="Guest URL" />
                    </Box>

                    <Box p={1}>
                        <FormControlLabel  control={<input style={{ marginRight: '8px' }} type="text" value={props.item != null ? props.item.electron_code : undefined} disabled={true} placeholder="aucun" />} label="Electron Code" />
                    </Box>

                    <Box p={1}>
                        <FormControlLabel className={classes.inputUrl} control={<input style={{ marginRight: '8px' }} type="text" value={props.item != null ? getCreateWebUrl(props.item) : undefined} disabled={true} placeholder="aucun" />} label="Create URL" />
                    </Box>

                    <Box p={1}>
                        <Button fullWidth onClick={props.onEdit} className="shadow-2 mb-4" style={{ width: '50%' }}>
                            Edit
                        </Button>
                        <Button fullWidth onClick={props.onDelete} className="shadow-2 mb-4" style={{ width: '50%' }}>
                            Delete
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};

function getWebUrl(code) {
    if(!code) return;

    return `${process.env.REACT_APP_WEB_URL}/from-planned/${code}`;
};

function getCreateWebUrl(sessionObj) {
    if(sessionObj.creation_code)
        return `${process.env.REACT_APP_WEB_URL}/create-planned-from-code/${sessionObj.creation_code}`;


    if(sessionObj.id)
        return `${process.env.REACT_APP_WEB_URL}/create-planned/${sessionObj.id}`;
    
    return "";
};

function getWordsListTitle(id, wordsList) {
    if(!id) return '';

    if(wordsList) {
        for(const item of wordsList)
            if(item._id == id) return item.name;
    }

    return id;
}

export default PlannedSessionDetails;