import React, {useEffect, useRef, useState} from 'react';
import BackdropLoader from "../../components/BackdropLoader/Backdrop";
import Alert from "../../components/Alert/Alert";
import UserService from '../../http-services/user.service';
import {i18n} from "../../utils/i18n";
import {default as MaterialTable} from "../../components/MaterialTable/MaterialTable";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Modal from '../../components/Modal/Modal';
import {Button} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { upperFirst } from '../../utils/string';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    btn:{
        margin: '15px 0',
        backgroundColor: '#cfdde4',
        "&:hover":{
            backgroundColor: '#7da0b0'
        }
    }
});



const Conversation = (props) => {
    
    const [loading, setLoading] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [deleteConfirmTrans, setDeleteConfirmTrans] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const alertRef = useRef();



    const getConversations= (callback) => {
        setLoading(true);
        UserService.getConversations()
            .then(response => {

                if(response.data instanceof Array) {
                    response.data.forEach(session => {
                        const newTranscriptions = [session.spoken_language];
                        if(session.spoken_language != session.written_language)
                            newTranscriptions.push(session.written_language);
        
                        if(session.transcriptions && session.transcriptions instanceof Array && !session.transcriptions.length) {
                            session.transcriptions.forEach(transcription => {
                                if(!transcription.lang || transcription.lang.startsWith('corrected_') ||
                                    newTranscriptions.includes(transcription.lang)) return;
                                newTranscriptions.push(transcription.lang);
                            });
                        }
                        session.transcriptions = newTranscriptions;
                    });
                }

                setConversations(response.data);
                setLoading(false);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                if (error.response) {
                    alertRef.current.toggle('error', error.response.data.message);
                }
                alertRef.current.toggle('error', error.toString());
                setLoading(false);
            })
    }

    useEffect(() => {
        getConversations();
    }, []);

    const downloadFile = (data) => {
        UserService.downloadFile(data).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            if(response.headers['content-type'] === "application/octet-stream"){
                link.setAttribute('download', `${data.conversationId}.zip`);
            }
            else{
                link.setAttribute('download', `${data.conversationId}.txt`);
            }
            document.body.appendChild(link);
            link.click();
          })
          .catch((err)=>{
               alert('Le fichier n\'existe pas.')
          });
    }

    const deleteFile = (data) => {
        setShowDeleteModal(false);
        UserService.deleteFile(data).then((response) => {
            setConversations(value => {
                value.forEach(v => {
                    if(v._id == data._id) v.downloadable = false;
                });
                return [...value];
            });
          })
          .catch((err)=>{
               alert(err.response.status == 401 ? 'Vous n\'avez pas la permission de supprimer ce fichier.' : 'Le fichier n\'existe pas.')
          });
    };

    const actions = [
        rowData => ({
            icon: CloudDownloadIcon,
            tooltip: rowData.downloadable ? 'Télécharger transcription' : undefined,
            onClick: (event, rowData) => downloadFile(rowData),
            disabled: !rowData.downloadable
        }),
        rowData => ({
            icon: DeleteOutlineIcon,
            tooltip: rowData.downloadable ? 'Supprimer transcription' : undefined,
            onClick: (event, rowData) => { setDeleteConfirmTrans(rowData); setShowDeleteModal(true); },
            disabled: !rowData.downloadable
        }),
    ]

    const columns = [
        { field: 'conversationId', title: i18n._("ID"), minWidth: 120 },
        { field: 'type', title: 'Type', minWidth: 120, render: data => { 
            return data.hygia ? (data.hygia_mode == 'phone' ? 'Hygia-Phone' : 'Hygia') : (data.type ? upperFirst(data.type) : 'Conversation');
        } },
        { field: 'participants', title: "Nb participants", minWidth: 120, render: data => { 
            return (data.hygia && data.hygia_mode != 'phone') ? 2 : (!data.participants.length ? 1 : data.participants.length); 
        } },
        { field: 'transcriptions', title: i18n._("Langues"), minWidth: 120, render: data => {
            return data.transcriptions && data.transcriptions.length ? data.transcriptions.map(i => ((i && i.includes('-')) ? i.split('-')[1] : i)).join(' | ') : 'N/A';
        }},
        { field: 'created_at', title: i18n._("Début"), minWidth: 120, render: data => new Date(data.created_at).toLocaleString() },
        { field: 'finished_at', title: i18n._("Fin"), minWidth: 200, align: 'left', render: data => !data.finished_at ? "En cours" : new Date(data.finished_at).toLocaleString() },
        { field: 'duration', title: i18n._("Durée réelle"), minWidth: 100, align: 'right',render: data => new Date(data.duration * 1000).toISOString().substr(11, 8) }
    ];

    const classes = useStyles();

    return (
        <div>
            <Alert ref={alertRef}/>
            <BackdropLoader open={loading}/>
            <MaterialTable
                columns={columns}
                data={conversations}
                title={i18n._("Conversations")}
                actions={actions}
            />
            {
                <Modal
                    open={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                >
                    <h4 style={{width: '100%', textAlign: 'center', marginBottom: '0'}}>Etes-vous sur de supprimer la transcription #{deleteConfirmTrans ? deleteConfirmTrans.conversationId : ''} ?</h4>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button type="button" className={`shadow-2 mb-4 ${classes.btn}`} style={{marginRight: '8px'}} onClick={() => deleteFile(deleteConfirmTrans)}>Confirmer</Button>
                        <Button type="button" className={`shadow-2 mb-4 ${classes.btn}`} onClick={() => setShowDeleteModal(false)}>Annuler</Button>
                    </div>
                </Modal>
            }
        </div>
    );
}

export default Conversation;