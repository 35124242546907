import React, { useEffect, useRef, useState } from 'react';
import BackdropLoader from "../../components/BackdropLoader/Backdrop";
import Alert from "../../components/Alert/Alert";

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import userService from '../../http-services/user.service';
import PlannedSessionForm from '../../components/Forms/PlannedSessions/PlannedSessionForm';
import { useMemo } from 'react';
import { Button } from '@material-ui/core';
import plannedSessionService from '../../http-services/planned-session.service';
import PlannedSessionDetails from '../../components/Forms/PlannedSessions/PlannedSessionDetails';
import wordsListService from '../../http-services/words-list.service';

const localizer = momentLocalizer(moment);

const PlannedSessions = (props) => {
    const [loading, setLoading] = useState(true);
    const [wordsListLoading, setWordsListLoading] = useState(true);
    const [plannedEvents, setPlannedEvents] = useState([]);
    const [wordsLists, setWordsLists] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [viewingEvent, setViewingEvent] = useState(null);
    const [viewingDisplayEvent, setViewingDisplayEvent] = useState(null);

    const alertRef = useRef();

    const refreshPlannedSessions = () => {
        setLoading(true);

        userService.getPlannedSessions()
            .then(res => {
                const newPlannedEvents = [];

                if(typeof res.data === 'object' && res.data instanceof Array) {
                    res.data.forEach(data => {
                        try {
                            const processedData = processPlannedSession(data);

                            if(processedData != null) newPlannedEvents.push(processedData);
                        }
                        catch(err) {}
                    });
                }

                setPlannedEvents(newPlannedEvents);
            })
            .catch(reason => {
                console.log(`failed to fetch planned sessions, reason:`, reason);
                alertRef.current.toggle('error', `Failed to fetch sessions : ${reason}`);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshPlannedSessions();

        wordsListService.getWordsLists()
            .then(res => {
                setWordsLists(res.data);
            })
            .catch(reason => {
                alertRef.current.toggle('error', `Failed to fetch words list : ${reason}`);
            })
            .finally(() => {
                setWordsListLoading(false);
            });
    }, []);

    const selectedEventObject = useMemo(() => {
        if(selectedEvent != null) {
            for(const plannedEvent of plannedEvents)
                if(plannedEvent.id == selectedEvent) return plannedEvent;
        }
        return null;
    }, [selectedEvent]);

    const viewingEventObject = useMemo(() => {
        if(viewingEvent != null) {
            for(const plannedEvent of plannedEvents)
                if(plannedEvent.id == viewingEvent) return plannedEvent;
        }
        return null;
    }, [viewingEvent]);

    const onEventSelection = (event) => {
        if(selectedEvent == event.id) return;

        setViewingDisplayEvent(event);
        setViewingEvent(event.id);
        //setSelectedEvent(event.id);
    };

    const onEventCreateClick = () => {
        setSelectedEvent(selectedEvent != null ? null : '0');
    };

    const onEventSubmit = (data) => {
        setSelectedEvent(null);
        setViewingEvent(null);

        if(!selectedEventObject) {
            setLoading(true);
            plannedSessionService.createPlannedSession(data)
                .then(() => {
                    refreshPlannedSessions();
                })
                .catch(err => {
                    setLoading(false);
                    console.log(`Failed to create the planned session, reason :`, err);
                    console.log(`Input data :`, data);

                    alertRef.current.toggle('error', `Failed to create : ${err}`);
                });
            return;
        }

        setLoading(true);
        plannedSessionService.updatePlannedSession(selectedEvent, data)
            .then(() => {
                refreshPlannedSessions();
            })
            .catch(err => {
                setLoading(false);
                console.log(`Failed to update the planned session "${selectedEvent}", reason :`, err);
                console.log(`Input data :`, data);

                alertRef.current.toggle('error', `Failed to update : ${err}`);
            });
    };

    const onEventEdit = () => {
        if(!viewingEvent) return;

        setViewingEvent(null);
        setSelectedEvent(viewingEvent);
    };

    const onEventDelete = () => {
        if(!viewingEvent) return;

        setViewingEvent(null);

        setLoading(true);
        plannedSessionService.removePlannedSession(viewingEvent)
            .then(() => {
                refreshPlannedSessions();
            })
            .catch(reason => {
                setLoading(false);
                console.log(`Failed to delete planned-session, reason:`, reason);
                alertRef.current.toggle('error', `Failed to delete : ${reason}`);
            });
    };

    return (
        <>
            <BackdropLoader open={loading} />
            <Alert ref={alertRef} />
            <Button fullWidth onClick={onEventCreateClick} className="shadow-2 mb-4">
                {selectedEvent == null ? "Create a planned-session" : "Cancel"}
            </Button>
            {
                selectedEvent != null && (
                    <PlannedSessionForm
                        updateItem={selectedEventObject}
                        wordsList={wordsLists}
                        onSubmit={data => onEventSubmit(data)}
                    />
                )
            }
            <div>
                <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView='week'
                    events={
                        plannedEvents
                    }
                    onSelectEvent={(e) => onEventSelection(e)}
                    style={{ height: '75vh' }}
                />
                <PlannedSessionDetails
                    open={viewingEventObject != null}
                    item={viewingDisplayEvent}
                    wordsList={wordsLists}
                    onClose={() => setViewingEvent(null)}
                    onEdit={() => onEventEdit()}
                    onDelete={() => onEventDelete()}
                />
            </div>
        </>
    );
}

function processPlannedSession(plannedSession) {
    const outSession = {
        ...plannedSession,
        id: plannedSession._id,
        title: plannedSession.title
    };

    try {
        outSession.start = new Date(plannedSession.planned_at);
        outSession.end = new Date(plannedSession.expire_at);
    }
    catch(err) {
        return null;
    }

    return outSession;
}

export default PlannedSessions;