import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Field, Form } from "react-final-form";
import { i18n } from "../../../utils/i18n";
import { Button, Chip, TextField, Typography, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { Trans } from "@lingui/macro";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const MaxCustomWords = 500;

const useStyles = makeStyles((theme) => ({
    chipWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        width: '100%',
        boxShadow: '0 0 3px #aaa',
        borderRadius: '10px',
        marginTop: '10px',
        padding: '4px',
        minHeight: '48px',
        alignItems: 'center'
    },
    emptyLabel: {
        margin: 0,
        color: '#aaa'
    }
}));

const CreateWordsListForm = (props) => {
    const [name, setName] = useState('');
    const [currentWord, setCurrentWord] = useState('');
    const [words, setWords] = useState([]);

    useEffect(() => {
        setName(!props.updateItem ? '' : props.updateItem.name);
        setWords(!props.updateItem ? [] : props.updateItem.list);
        setCurrentWord('');
    }, [props.updateItem]);

    const onSubmit = async () => {
        if (!name || !words.length) return;

        if (props.onSubmit && !(await props.onSubmit({ name: name, words: words })))
            return;

        setName('');
        setCurrentWord('');
        setWords([]);
    };

    const defineCurrentWord = value => {
        setCurrentWord(
            value.replace(';', '')
        );
    };

    const appendWordItem = () => {
        const currentWords = currentWord.split(',');
        for(const word of currentWords) {
            const appendedWord = word.trim();
            if (!appendedWord.length || words.includes(appendedWord) || words.length >= MaxCustomWords) continue;
            setWords(state => [...state, appendedWord]);
        }
        setCurrentWord('');
    };

    const deleteWordItem = word => {
        setWords(state => [...(state.filter(w => w != word))]);
    };

    const onCancel = () => {
        if (props.onCancel) props.onCancel();
    };

    const classes = useStyles();

    return (
        <>
            <form>
                <Typography variant="h5" align="center">
                    {
                            props.updateItem 
                        ?
                            (<Trans>Edit a dictionnary</Trans>)
                        :
                            (<Trans>Create a dictionnary</Trans>)
                    }
                </Typography>
                <Box p={1}>
                    <TextField label={props.updateItem ? "Editing Dictionnary" : "Dictionnary Name"} variant="outlined" fullWidth onChange={(e) => (!props.updateItem && setName(e.target.value))} value={name} />
                </Box>
                <Box p={1}>
                    <TextField
                        label={`Insert words here (${words.length}/${MaxCustomWords})`}
                        fullWidth
                        onChange={(e) => defineCurrentWord(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key != 'Enter') return;
                            e.preventDefault();
                            appendWordItem();
                        }}
                        value={currentWord}
                    />
                    <div className={classes.chipWrapper}>
                        {words.length ? words.map(word => (
                            <Chip key={`word-item-${word}`} label={word} variant="outlined" onDelete={() => deleteWordItem(word)} />
                        )) : (<label className={classes.emptyLabel}><Trans>No words</Trans></label>)}
                    </div>
                </Box>
                <Button fullWidth onClick={onSubmit} className="shadow-2 mb-4" style={{ width: props.updateItem ? '50%' : undefined }}>
                    {
                        props.updateItem 
                            ?
                        (<Trans>Update Dictionnary</Trans>)
                            :
                        (<Trans>Create a Dictionnary</Trans>)
                    }
                </Button>
                {props.updateItem && (
                    <Button fullWidth onClick={onCancel} className="shadow-2 mb-4" style={{ width: props.updateItem ? '50%' : undefined }}>
                        <Trans>Cancel Edit</Trans>
                    </Button>
                )}
            </form>
        </>
    );
}

export default CreateWordsListForm;