import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Field, Form } from "react-final-form";
import { i18n } from "../../../utils/i18n";
import { Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, TextField, Typography, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { Trans } from "@lingui/macro";
import React from "react";
import { Delete, Subject } from "@material-ui/icons";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    emptyLabel: {
        margin: 0,
        color: '#aaa',
        width: '100%',
        textAlign: 'center',
        display: 'inline-block'
    }
}));

const EditWordsListsForm = (props) => {
    const onDeleteItem = item => {
        if (props.onDelete) props.onDelete(item);
    };

    const clickItem = item => {
        if (props.onClick) props.onClick(item);
    };

    const classes = useStyles();

    return (
        <>
            <Typography variant="h5" align="center">
                <Trans>
                    Your dictionaries
                </Trans>
            </Typography>
            <List>
                {props.items && props.items.length ? props.items?.map(item => (
                    <ListItem
                        key={`list-words-item-${item.name}`}
                        button
                        selected={item.name === props.selectedItem}
                        onClick={() => clickItem(item.name)}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <Subject />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={item.name}
                            secondary={`(${item.list.length} mots)`}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => onDeleteItem(item.name)}
                            >
                                <Delete />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )) : (<label className={classes.emptyLabel}><Trans>No dictionnaries</Trans></label>)}
            </List>
        </>
    );
}

export default EditWordsListsForm;