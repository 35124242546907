import Box from "@material-ui/core/Box";
import { Button, Checkbox, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography, makeStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { formatDateToDatetimeLocal } from "../../../utils/date";

const PlannedSessionForm = (props) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [plannedDate, setPlannedDate] = useState(null);
    const [expireDate, setExpireDate] = useState(null);

    const [allowTranslation, setAllowTranslation] = useState(false);
    const [selectedWordsList, setSelectedWordsList] = useState(null);

    const [allowReviser, setAllowReviser] = useState(false);
    const [allowChat, setAllowChat] = useState(false);
    const [allowCompanion, setAllowCompanion] = useState(false);

    useEffect(() => {
        setTitle(!props.updateItem ? '' : props.updateItem.title);
        setDescription(!props.updateItem ? '' : props.updateItem.description);

        setPlannedDate(!props.updateItem ? null : formatDateToDatetimeLocal( props.updateItem.start ));
        setExpireDate(!props.updateItem ? null : formatDateToDatetimeLocal( props.updateItem.end ));

        setAllowTranslation(!props.updateItem ? null : props.updateItem.translation_allowed == true);
        setSelectedWordsList(!props.updateItem ? null : props.updateItem.wordsList);

        setAllowReviser(!props.updateItem ? null : props.updateItem.reviser_code != null);
        setAllowChat(!props.updateItem ? null : props.updateItem.chat_code != null);
        setAllowCompanion(!props.updateItem ? null : props.updateItem.companion_code != null);
    }, [props.updateItem]);

    const onSubmit = async () => {
        if (!title.length || !description.length || !plannedDate || !expireDate) return;

        let processedPlannedDate = null, processedExpireDate = null;

        try {
            processedPlannedDate = new Date(plannedDate).toUTCString();
        }
        catch(err) {
            console.log(`Failed to process planned date`);
        }

        try {
            processedExpireDate = new Date(expireDate).toUTCString();
        }
        catch(err) {
            console.log(`Failed to process expire date`);
        }

        /*if (props.onSubmit && !(await props.onSubmit({ name: title, words: words })))
            return;*/

        if(props.onSubmit) {
            props.onSubmit({
                title,
                description,
                planned_date: processedPlannedDate,
                expire_date: processedExpireDate,
                translation_allowed: allowTranslation,
                words_list: selectedWordsList,
                reviser: allowReviser,
                chat: allowChat,
                companion: allowCompanion
            });
        }

        setTitle('');
        setDescription('');
    };

    return (
        <>
            <form style={{ marginTop: '10px' }}>
                <Typography variant="h5" align="center">
                    {
                            props.updateItem 
                        ?
                            (<Trans>Edit a planned-session</Trans>)
                        :
                            (<Trans>Create a planned-session</Trans>)
                    }
                </Typography>
                <Box p={1}>
                    <TextField label={props.updateItem ? "Editing Planned-Session" : "Planned-Session Title"} variant="outlined" fullWidth onChange={(e) => (setTitle(e.target.value))} value={title} />
                </Box>
                <Box p={1}>
                    <TextField label="Description" variant="outlined" fullWidth onChange={(e) => (setDescription(e.target.value))} value={description} />
                </Box>
                <Box p={1}>
                    <FormControlLabel style={{ paddingLeft: '12px' }} control={<input style={{ marginRight: '8px' }} type="datetime-local" value={plannedDate} onChange={e => setPlannedDate(e.target.value)} />} label="Planned at" />
                    <FormControlLabel style={{ paddingLeft: '12px' }} control={<input style={{ marginRight: '8px' }} type="datetime-local" value={expireDate} onChange={e => setExpireDate(e.target.value)} />} label="Expire at" />
                </Box>
                <Box p={2}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel>Words List</InputLabel>
                        <Select
                            value={!selectedWordsList ? "0" : selectedWordsList}
                            onChange={(e) => setSelectedWordsList(e.target.value == '0' ? null : e.target.value)}
                        >
                            <MenuItem value="0">Aucun</MenuItem>
                            {
                                props.wordsList != null && props.wordsList.map(item => {
                                    return (<MenuItem value={item._id}>{ item.name }</MenuItem>);
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box p={1}>
                    <FormControlLabel control={<Checkbox checked={allowTranslation} onChange={() => setAllowTranslation(state => !state)} />} label="Allow translation" />
                </Box>
                <Box p={1}>
                    <FormControlLabel control={<Checkbox checked={allowReviser} onChange={() => setAllowReviser(state => !state)} />} label="Allow reviser" />
                    <FormControlLabel control={<Checkbox checked={allowChat} onChange={() => setAllowChat(state => !state)} />} label="Allow chat" />
                    <FormControlLabel control={<Checkbox checked={allowCompanion} onChange={() => setAllowCompanion(state => !state)} />} label="Allow companion" />
                </Box>
                <Button fullWidth onClick={onSubmit} className="shadow-2 mb-4">
                    {
                        props.updateItem 
                            ?
                        (<Trans>Update Planned-Session</Trans>)
                            :
                        (<Trans>Create a Planned-Session</Trans>)
                    }
                </Button>
            </form>
        </>
    );
}

export default PlannedSessionForm;