import http from './http-common';
class PlannedSessionService {
    createPlannedSession(body) {
        return http.post('/planned-session/create', body);
    }

    getPlannedSession(id) {
        return http.get(`/planned-session/get/${id}`);
    }

    updatePlannedSession(id, body) {
        return http.put(`/planned-session/update/${id}`, body);
    }

    removePlannedSession(id) {
        return http.delete(`/planned-session/delete/${id}`);
    }
}

export default new PlannedSessionService();